@charset "UTF-8";

@import "_setting.scss";

/* =====================
	TOPページ
 ======================= */

/*********** MV ***********/
.p-mv {
	position: relative;
	
	&__txt {
		position: absolute;
		right: per(32);
		top: per(500, 900);
		width: per(343);
	}
}

/*********** LEAD ***********/
.p-lead {
	background: url(../img/home/lead_bg01.png) no-repeat center top /100%;
	
	&__title {
		width: per(743, 1000);
		margin: 0 auto per(120, 1000);
	}
	
	&__box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: per(100, 1000);
		
		&__picBox {
			width: per(425, 1000);
			position: relative;
			
			&:before {
				content: "";
				width: per(137, 425);
				padding-top: per(239, 425);
				background: url(../img/home/lead_img02.png) no-repeat bottom left /contain;
				position: absolute;
				right: per(-96, 425);
				top: per(-104, 360);
			}
		}
		
		&__txtBox {
			width: per(490, 1000);
		}
		
		&__title {
			margin-bottom: em(20);
			width: per(403, 490);
		}
	}
	
	&__gallery {
		margin-bottom: per(52, 1000);
		background: url(../img/home/lead_bg02.png) no-repeat bottom center /100%;
		position: relative;
		
		&:before {
			content: "";
			width: 100%;
			padding-top: per(101);
			background: url(../img/home/lead_bg03.png) no-repeat center /contain;
			position: absolute;
			top: 0;
			margin-top: per(-60);
			left: 0;
			z-index: -1;
		}
		
		&__title {
			width: per(410, 1000);
			margin: 0 auto per(40, 1000);
		}
		
		&__lists {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: em(20);
		}
	}
}

/*********** INSTAGRAM ***********/
.p-ig {
	position: relative;
	
	&:before {
		content: "";
		width: 100%;
		padding-top: per(1324);
		background: url(../img/home/ig_bg01.png) no-repeat top center /100%;
		position: absolute;
		top: 0;
		margin-top: per(-100);
		left: 0;
		z-index: -1;
	}
	
	&__title {
		width: per(641, 1000);
		margin: 0 auto per(60, 1000);
	}
	
	&__lists {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: em(20);
		> li {
			a {
				display: block;
				width: 100%;
				padding-top: 100%;
				overflow: hidden;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
	
	&__link {
		margin-top: per(60, 1000);
	}
	
	&__btn {
		width: per(355, 1000);
	}
}

/*********** PLACE ***********/
.p-place {
	position: relative;
	padding-bottom: per(156);
	
	&:before {
		content: "";
		width: per(119);
		padding-top: per(264);
		background: url(../img/home/place_img01.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(175);
		left: per(295);
		z-index: -1;
	}
	
	&:after {
		content: "";
		width: per(294);
		padding-top: per(282);
		background: url(../img/home/place_img02.png) no-repeat center /contain;
		position: absolute;
		bottom: 0;
		margin-bottom: per(60);
		right: per(260);
		z-index: -1;
	}
	
	&__title {
		width: per(552, 1000);
		margin: 0 auto per(55, 1000);
	}
	
	&__txt {
		width: per(832, 1000);
		margin: 0 auto;
	}
}

/*********** ACCESS ***********/
.p-access {
	background: var(--c_red);
	position: relative;
	
	&:before {
		content: "";
		width: per(141);
		padding-top: per(177);
		background: url(../img/home/access_img01.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(-100);
		left: per(220);
	}
	
	.p-inner {
		position: relative;
		padding: per(140) 0 per(120);
	}
	
	&__title {
		position: absolute;
		top: 0;
		margin-top: per(-105, 1000);
		left: 0;
		width: per(439, 1000);
	}
	
	&__map {
		position: relative;
		margin-bottom: per(60, 1000);
		
		&:before {
			content: "";
			width: per(440, 1000);
			padding-top: per(480, 1000);
			background: url(../img/home/access_img02.png) no-repeat bottom left /contain;
			position: absolute;
			bottom: 0;
			margin-bottom: per(-140, 1000);
			right: per(-300, 1000);
		}
	}
	
	&__link {
	}
	
	&__btn {
		width: per(490, 1000);
	}
}

/*********** PARKING ***********/
.p-parking {
	background: var(--c_base);
	position: relative;
	z-index: 2;
	
	&:before {
		content: "";
		width: per(393);
		padding-top: per(435);
		background: url(../img/home/parking_img01.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(48);
		right: per(350);
		z-index: -1;
	}
	
	&__title {
		width: per(130, 1000);
		margin: 0 auto per(16, 1000);
	}
	
	&__detail {
		font-size: fs(20);
		line-height: (33.2 / 20);
		
		&__lists {
			margin-bottom: em(33.2, 20);
		}
		
		&__dl {
		}
	}
}

/*********** INFO ***********/
.p-info {
	position: relative;
	
	&:before {
		content: "";
		width: per(206);
		padding-top: per(256);
		background: url(../img/home/info_img01.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(-72);
		left: per(204);
		z-index: 2;
	}
	
	&:after {
		content: "";
		width: per(120);
		padding-top: per(259);
		background: url(../img/home/info_img02.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(145);
		right: per(236);
	}
	
	&__title {
		margin: 0 auto per(20, 1000);
		
		&--forShop {
			width: per(254, 1000);
		}
		&--notice {
			width: per(122, 1000);
		}
	}
	
	&__txt {
		text-align: center;
		font-size: fs(17);
		line-height: (33.2 / 17);
	}
	
	&__forShop {
		margin-bottom: per(50, 1000);
	}
	
	&__notice {
		margin-bottom: per(50, 1000);
	}
	
	&__links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: em(44);
	}
	
	&__link {
	}
	
	&__btn {
		&--01 {
			width: per(490, 1000);
		}
		&--02 {
			width: per(355, 1000);
		}
		&--03 {
			width: per(490, 1000);
		}
	}
}

/*********** FAMILY ***********/
.p-family {
	background: var(--c_base);
	
	&__title {
		width: per(411, 1000);
		margin: 0 auto per(20, 1000);
	}
	
	&__catch {
		margin-bottom: per(50, 1000);
		text-align: center;
	}
	
	&__lists {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: em(48) per(42, 1000);
	}
}

/* タブレット
  ------------------------ */
@media (max-width: $breakpoint_tb) {
}

/* スマホ
  ------------------------ */
@media (max-width: $breakpoint_sp) {
	//*********** MV ***********/
	
	//*********** LEAD ***********/
	.p-lead {
		//background: url(../img/home/lead_bg01.png) no-repeat center top /100%;
		background: none;
		
		&__title {
			width: per(743*1.2, 1000);
			margin-bottom: 20px;
		}
		
		&__box {
			//margin-bottom: per(100, 1000);
			row-gap: 32px;
			
			&__picBox {
				width: 100%;
				
				&:before {
					padding-top: 32%;
					right: -10px;
					top: 80%;
					background-position: right bottom;
				}
			}
			
			&__txtBox {
				width: 100%;
			}
			
			&__title {
				margin-bottom: 10px;
			}
		}
		
		&__gallery {
			margin-bottom: 24px;
			background: none;
			
			&:before {
				background: url(../img/home/lead_bg03_sp.png) no-repeat center /contain;
				height: 40px;
			}
			
			&__title {
				width: per(410*1.6, 1000);
				margin-bottom: 20px;
			}
			
			&__lists {
				gap: 5px;
			}
		}
	}
	
	/*********** INSTAGRAM ***********/
	.p-ig {
		&:before {
			display: none;
		}
		
		&__title {
			width: per(641*1.4, 1000);
			margin-bottom: 20px;
		}
		
		&__lists {
			grid-template-columns: repeat(3, 1fr);
			gap: 5px;
		}
		
		&__link {
			margin-top: 32px;
		}
		
		&__btn {
			width: per(355*1.4, 1000);
		}
	}
	
	/*********** PLACE ***********/
	.p-place {
		padding-bottom: 72px;
		
		&:before {
			left: 5%;
		}
		
		&:after {
			margin-bottom: 60px;
			right: 7%;
		}
		
		&__title {
			width: per(552*1.2, 1000);
			margin-bottom: 20px;
		}
		
		&__txt {
			width: per(832, 1000);
		}
	}
	
	/*********** ACCESS ***********/
	.p-access {
		&:before {
			left: 1.5%;
		}
		
		.p-inner {
			padding: 40px 0 32px;
		}
		
		&__title {
			margin-top: -52px;
			left: 20px;
		}
		
		&__map {
			&:before {
				width: 25%;
				right: -2%;
				bottom: -3%;
				margin-bottom: 0;
			}
		}
		
		&__btn {
			width: per(490*1.2, 1000);
		}
	}
	
	/*********** PARKING ***********/
	.p-parking {
		&:before {
			right: 0;
			margin-top: 0;
			transform: scale(1.5);
			transform-origin: top right;
		}
		
		&__title {
			width: per(130*1.8, 1000);
			margin-bottom: 20px;
		}
		
		&__detail {
			font-size: 100%;
		}
	}
	
	/*********** INFO ***********/
	.p-info {
		&:before {
			transform: scale(1.5);
			left: 5%;
		}
		
		&:after {
			transform: scale(1.5);
			transform-origin: right;
			right: 3%;
			margin-top: 35%;
		}
		
		&__title {
			//margin: 0 auto per(20, 1000);
			
			&--forShop {
				width: per(254*1.8, 1000);
			}
			&--notice {
				width: per(122*1.8, 1000);
			}
		}
		
		&__txt {
			text-align: left;
			font-size: 100%;
		}
		
		&__forShop {
			margin-bottom: 24px;
		}
		
		&__notice {
			margin-bottom: 24px;
		}
		
		&__links {
			row-gap: 20px;
		}
		
		&__btn {
			&--01 {
				width: per(490*1.8, 1000);
			}
			&--02 {
				width: per(355*1.8, 1000);
			}
			&--03 {
				width: per(490*1.8, 1000);
			}
		}
	}
	
	/*********** FAMILY ***********/
	.p-family {
		&__title {
			width: per(411*1.8, 1000);
		}
		
		&__catch {
			margin-bottom: 20px;
		}
		
		&__lists {
			display: flex;
			flex-wrap: wrap;
			gap: 20px 5px;
			justify-content: center;
		}
		
		&__list {
			width: calc((100% - 5px * 3) / 4);
		}
	}
}