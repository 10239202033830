@charset "UTF-8";
/* =====================
	TOPページ
 ======================= */
/*********** MV ***********/
.p-mv {
  position: relative;
}

.p-mv__txt {
  position: absolute;
  right: 1.66667%;
  top: 55.55556%;
  width: 17.86458%;
}

/*********** LEAD ***********/
.p-lead {
  background: url(../img/home/lead_bg01.png) no-repeat center top/100%;
}

.p-lead__title {
  width: 74.3%;
  margin: 0 auto 12%;
}

.p-lead__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10%;
}

.p-lead__box__picBox {
  width: 42.5%;
  position: relative;
}

.p-lead__box__picBox:before {
  content: "";
  width: 32.23529%;
  padding-top: 56.23529%;
  background: url(../img/home/lead_img02.png) no-repeat bottom left/contain;
  position: absolute;
  right: -22.58824%;
  top: -28.88889%;
}

.p-lead__box__txtBox {
  width: 49%;
}

.p-lead__box__title {
  margin-bottom: 1.25em;
  width: 82.2449%;
}

.p-lead__gallery {
  margin-bottom: 5.2%;
  background: url(../img/home/lead_bg02.png) no-repeat bottom center/100%;
  position: relative;
}

.p-lead__gallery:before {
  content: "";
  width: 100%;
  padding-top: 5.26042%;
  background: url(../img/home/lead_bg03.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: -3.125%;
  left: 0;
  z-index: -1;
}

.p-lead__gallery__title {
  width: 41%;
  margin: 0 auto 4%;
}

.p-lead__gallery__lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25em;
}

/*********** INSTAGRAM ***********/
.p-ig {
  position: relative;
}

.p-ig:before {
  content: "";
  width: 100%;
  padding-top: 68.95833%;
  background: url(../img/home/ig_bg01.png) no-repeat top center/100%;
  position: absolute;
  top: 0;
  margin-top: -5.20833%;
  left: 0;
  z-index: -1;
}

.p-ig__title {
  width: 64.1%;
  margin: 0 auto 6%;
}

.p-ig__lists {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25em;
}

.p-ig__lists > li a {
  display: block;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}

.p-ig__lists > li a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.p-ig__link {
  margin-top: 6%;
}

.p-ig__btn {
  width: 35.5%;
}

/*********** PLACE ***********/
.p-place {
  position: relative;
  padding-bottom: 8.125%;
}

.p-place:before {
  content: "";
  width: 6.19792%;
  padding-top: 13.75%;
  background: url(../img/home/place_img01.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: 9.11458%;
  left: 15.36458%;
  z-index: -1;
}

.p-place:after {
  content: "";
  width: 15.3125%;
  padding-top: 14.6875%;
  background: url(../img/home/place_img02.png) no-repeat center/contain;
  position: absolute;
  bottom: 0;
  margin-bottom: 3.125%;
  right: 13.54167%;
  z-index: -1;
}

.p-place__title {
  width: 55.2%;
  margin: 0 auto 5.5%;
}

.p-place__txt {
  width: 83.2%;
  margin: 0 auto;
}

/*********** ACCESS ***********/
.p-access {
  background: var(--c_red);
  position: relative;
}

.p-access:before {
  content: "";
  width: 7.34375%;
  padding-top: 9.21875%;
  background: url(../img/home/access_img01.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: -5.20833%;
  left: 11.45833%;
}

.p-access .p-inner {
  position: relative;
  padding: 7.29167% 0 6.25%;
}

.p-access__title {
  position: absolute;
  top: 0;
  margin-top: -10.5%;
  left: 0;
  width: 43.9%;
}

.p-access__map {
  position: relative;
  margin-bottom: 6%;
}

.p-access__map:before {
  content: "";
  width: 44%;
  padding-top: 48%;
  background: url(../img/home/access_img02.png) no-repeat bottom left/contain;
  position: absolute;
  bottom: 0;
  margin-bottom: -14%;
  right: -30%;
}

.p-access__btn {
  width: 49%;
}

/*********** PARKING ***********/
.p-parking {
  background: var(--c_base);
  position: relative;
  z-index: 2;
}

.p-parking:before {
  content: "";
  width: 20.46875%;
  padding-top: 22.65625%;
  background: url(../img/home/parking_img01.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: 2.5%;
  right: 18.22917%;
  z-index: -1;
}

.p-parking__title {
  width: 13%;
  margin: 0 auto 1.6%;
}

.p-parking__detail {
  font-size: 125%;
  line-height: 1.66;
}

.p-parking__detail__lists {
  margin-bottom: 1.66em;
}

/*********** INFO ***********/
.p-info {
  position: relative;
}

.p-info:before {
  content: "";
  width: 10.72917%;
  padding-top: 13.33333%;
  background: url(../img/home/info_img01.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: -3.75%;
  left: 10.625%;
  z-index: 2;
}

.p-info:after {
  content: "";
  width: 6.25%;
  padding-top: 13.48958%;
  background: url(../img/home/info_img02.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: 7.55208%;
  right: 12.29167%;
}

.p-info__title {
  margin: 0 auto 2%;
}

.p-info__title--forShop {
  width: 25.4%;
}

.p-info__title--notice {
  width: 12.2%;
}

.p-info__txt {
  text-align: center;
  font-size: 106.25%;
  line-height: 1.95294;
}

.p-info__forShop {
  margin-bottom: 5%;
}

.p-info__notice {
  margin-bottom: 5%;
}

.p-info__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2.75em;
}

.p-info__btn--01 {
  width: 49%;
}

.p-info__btn--02 {
  width: 35.5%;
}

.p-info__btn--03 {
  width: 49%;
}

/*********** FAMILY ***********/
.p-family {
  background: var(--c_base);
}

.p-family__title {
  width: 41.1%;
  margin: 0 auto 2%;
}

.p-family__catch {
  margin-bottom: 5%;
  text-align: center;
}

.p-family__lists {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3em 4.2%;
}

/* タブレット
  ------------------------ */
/* スマホ
  ------------------------ */
@media (max-width: 699px) {
  .p-lead {
    background: none;
  }
  .p-lead__title {
    width: 89.16%;
    margin-bottom: 20px;
  }
  .p-lead__box {
    row-gap: 32px;
  }
  .p-lead__box__picBox {
    width: 100%;
  }
  .p-lead__box__picBox:before {
    padding-top: 32%;
    right: -10px;
    top: 80%;
    background-position: right bottom;
  }
  .p-lead__box__txtBox {
    width: 100%;
  }
  .p-lead__box__title {
    margin-bottom: 10px;
  }
  .p-lead__gallery {
    margin-bottom: 24px;
    background: none;
  }
  .p-lead__gallery:before {
    background: url(../img/home/lead_bg03_sp.png) no-repeat center/contain;
    height: 40px;
  }
  .p-lead__gallery__title {
    width: 65.6%;
    margin-bottom: 20px;
  }
  .p-lead__gallery__lists {
    gap: 5px;
  }
  /*********** INSTAGRAM ***********/
  .p-ig:before {
    display: none;
  }
  .p-ig__title {
    width: 89.74%;
    margin-bottom: 20px;
  }
  .p-ig__lists {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
  .p-ig__link {
    margin-top: 32px;
  }
  .p-ig__btn {
    width: 49.7%;
  }
  /*********** PLACE ***********/
  .p-place {
    padding-bottom: 72px;
  }
  .p-place:before {
    left: 5%;
  }
  .p-place:after {
    margin-bottom: 60px;
    right: 7%;
  }
  .p-place__title {
    width: 66.24%;
    margin-bottom: 20px;
  }
  .p-place__txt {
    width: 83.2%;
  }
  /*********** ACCESS ***********/
  .p-access:before {
    left: 1.5%;
  }
  .p-access .p-inner {
    padding: 40px 0 32px;
  }
  .p-access__title {
    margin-top: -52px;
    left: 20px;
  }
  .p-access__map:before {
    width: 25%;
    right: -2%;
    bottom: -3%;
    margin-bottom: 0;
  }
  .p-access__btn {
    width: 58.8%;
  }
  /*********** PARKING ***********/
  .p-parking:before {
    right: 0;
    margin-top: 0;
    transform: scale(1.5);
    transform-origin: top right;
  }
  .p-parking__title {
    width: 23.4%;
    margin-bottom: 20px;
  }
  .p-parking__detail {
    font-size: 100%;
  }
  /*********** INFO ***********/
  .p-info:before {
    transform: scale(1.5);
    left: 5%;
  }
  .p-info:after {
    transform: scale(1.5);
    transform-origin: right;
    right: 3%;
    margin-top: 35%;
  }
  .p-info__title--forShop {
    width: 45.72%;
  }
  .p-info__title--notice {
    width: 21.96%;
  }
  .p-info__txt {
    text-align: left;
    font-size: 100%;
  }
  .p-info__forShop {
    margin-bottom: 24px;
  }
  .p-info__notice {
    margin-bottom: 24px;
  }
  .p-info__links {
    row-gap: 20px;
  }
  .p-info__btn--01 {
    width: 88.2%;
  }
  .p-info__btn--02 {
    width: 63.9%;
  }
  .p-info__btn--03 {
    width: 88.2%;
  }
  /*********** FAMILY ***********/
  .p-family__title {
    width: 73.98%;
  }
  .p-family__catch {
    margin-bottom: 20px;
  }
  .p-family__lists {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 5px;
    justify-content: center;
  }
  .p-family__list {
    width: calc((100% - 5px * 3) / 4);
  }
}
