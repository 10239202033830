// ブレークポイント
$breakpoint_sp:699px;
$breakpoint_pc:$breakpoint_sp + 1;
$breakpoint_tb:1100px;

@function vw($px) {
  $vw : ($px / 1920 * 100);
  @return ($vw)+vw;
  //@return ($px)+px;
}

@function rem($px) {
  $rem : ($px / 16);
  @return ($rem)+rem;
  //@return ($rem)+px;
}

@function per($px, $parent:1920) {
  $per : ($px / $parent * 100%);
  @return ($per);
  //@return ($px)+px;
}

@function fs($px, $parent:16) {
  $fs : ($px / $parent * 100%);
  @return ($fs);
}

@function em($px, $parent:16) {
  $em : ($px / $parent);
  @return ($em) + em;
}

